import axios from "axios";

function sendContactForm({ data }) {
  return axios({
    method: "POST",
    url: "/.netlify/functions/sendmail",
    data,
  });
}

export default sendContactForm;
