import axios from "axios";

function signUpToMailingList({ email }) {
  return axios
    .get(
      `https://ymlp.com/subscribe.php?id=${process.env.GATSBY_YMLP_ID}&YMP0=${email}`
    )
    .catch(() => Promise.resolve());
}

export default signUpToMailingList;
